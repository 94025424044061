<template>
  <div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
<style lang="scss">

</style>
